<template>
  <div>
    <a-modal :width="650" :visible="modal.visible" @cancel="closeModal" :centered="true" :footer="null">
      <div class="mt-3">
		<video v-if="modal.type === 'video'" style="width:100%;" controls>
			<source :src="modal.image" type="video/mp4">
			Your browser does not support the video tag.
		</video>
        <img v-else style="object-fit:contain; width:100%" :src="modal.image" />
      </div>
    </a-modal>

    <div class="image-holder-box"  @mouseover="showOptions = true" @mouseleave="showOptions = false">
      <div v-if="img === ''"  @click="$store.commit('MEDIA_SELECT',{callback:selectedMarkerImage})">
        <ul style="list-style-type: none; padding: 0;text-align:center;margin-top:50px">
          <li style="padding-bottom: 30px;"><a-icon type="plus" style="color:white; font-size:30px; background-color:#ECE9F1; padding:15px; border-radius:50px" /></li>
          <li>{{text}}</li>
        </ul>
      </div>
      <div v-else class="relative">
        <div v-show="showOptions" class="image-selector">
          <a-button-group>
            <!-- <a-button>Image</a-button> -->
            <a-button @click="$store.commit('MEDIA_SELECT',{callback:selectedMarkerImage})"><a-icon type="edit" /></a-button>
            <a-button @click="previewImage(img)"><a-icon type="eye" /></a-button>
            <a-button @click="$emit('callback', '')"><a-icon type="delete" /></a-button>
          </a-button-group>
        </div>
        <div v-show="showOptions" style="width: 100%; height: 203px; background-color: rgba(64, 69, 76, 0.5); position: absolute;"></div>
		<div v-if="img && img.includes('.mp4')" class="file-icon ml-1 file-icon-xl" data-type=".mp4" style="height:200px; width: 200px; left: 50%; transform: translateX(-50%);"></div>
        <div v-else style="background-repeat: no-repeat; background-size: contain; background-position: 50%; width: 100%; height: 203px;" :style="{ 'background-image': 'url(' + img + ')' }" />
      </div>
    </div>
  </div>
</template>

<script>

import ImageBoxSelector from 'bh-mod/components/common/ImageBoxSelector'

export default {
	components:{ImageBoxSelector},
	props: {
		img:{
			default: '',
			type: String
		},
		text:{
			default: 'Add Image',
			type: String
		}
	},
    data() {
        return {
          showOptions: false,
          newImg: '',
          modal:{
            visible:false,
            image:'',
			type:''
          }
        };
    },
    watch:{
        
    },
    computed: {
        
    },
    methods: {
      selectedMarkerImage(url){
        this.newImg = url.url
        this.$emit('callback', this.newImg)
        this.$emit('formats', url.formats)
      },
      previewImage(img) {
		let video = ['.mp4']
		let image = ['.jpg','.jpeg','.png','.gif','.webp','.svg']
		if (video.some(v => img.includes(v))) {
			this.modal.type = 'video'
		} else if (image.some(i => img.includes(i))) {
			this.modal.type = 'image'
		}
        this.modal.visible = true
        this.modal.image = img
      },
	  closeModal(){
		this.modal.visible = false
		this.modal.image = ''
		this.modal.type = ''
	  }
    },
};
</script>

<style scoped>
.image-holder-box{
      display: table;
      width: 100%;
      height: 203px;
      vertical-align: top; 
      background-color: #fafafa;
      border: 1px dashed #d9d9d9;
      border-radius: 4px;
      cursor: pointer;
  }
  .image-holder-box:hover{
      border-color: var(--orange);
      transition: border-color .3s ease;
  }
  .image-holder-box .content{
    display: table-cell;
    width: 100%;
    height: 100%;
    padding: 8px;
    text-align: center;
    vertical-align: middle;
  }
  .image-holder-box .content .inside{
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 50%;
    width: 100%;
    height: 203px;
  }
  .image-selector {
	position: absolute;
	z-index: 1000;
	width: 100%;
  height:100%;
  justify-content: center;
  align-items: center;
  display: flex;

  /* margin-top: -16px;
  top: 50%;

  margin-left: -79.08px;
  left: 50%; */
  

  }
  .edit-current-marker{
	position: absolute;
	background-color: var(--white);
	top: 0;
	z-index: 100;
	left: 80px;
  }
</style>