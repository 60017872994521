<template>
    <div class="w-full dF fC f1 px-4 pb-4 hide-scrollbar" style="overflow: scroll; background-color: #F7F5F9;">

        <div class="contacts-page">

            <BHLoading :show="loadingPage" />
			<div class="mb-2" style="display: inline-block;">
				<div style="display: inline-block; cursor: pointer;">
					<div @click="$router.push('/features')" class="dF">
						<a-icon class="mr-4" style="font-size: 1.75rem;" type="arrow-left" />
						<h3>Back to All Features</h3>
					</div>
				</div>
			</div>
            <h4 class="mb-3 mt-3">{{title}}</h4>

            <div class="contacts-page dF h-full w-full">
                <div class="responsiveFlex w-full">
                    <div class="rightSide" style="order: 2;">
                        <a-card class="mb-4" title="Status and Visibility">
                            <a-select v-model="newFeature.status" style="width:100%;" size="large" >
                                <a-select-option value="draft">Draft</a-select-option>
                                <a-select-option value="published">Published</a-select-option>
                            </a-select>
                            <div class="mt-4 dF aC jE w-full" style="gap: 1.5em;">
                                <div @click="deleteFeature()" v-if="type == 'edit' && $p > 10" class="f1" style="color: var(--orange); text-decoration: underline; cursor: pointer;">
                                    DELETE FEATURES & FINISHES
                                </div>
                                <a-button @click="addFeature" type="primary" size="large">SAVE</a-button>
                            </div>
                        </a-card>
                        <a-card class="mb-4 relative" title="Thumbnail Image">
							<div class="absolute" style="top: 15px; left: 145px; font-size: 16px; color: var(--danger);">*</div>
                            <ImageBox :img="newFeature.thumbnail" @callback="selectThumbnail" @formats="formats"/>
                        </a-card>
                    </div>
                    <div class="leftSide">
                        <a-card title="Title" class="relative">
							<div class="absolute" style="top: 15px; left: 58px; font-size: 16px; color: var(--danger);">*</div>
                            <a-input v-model="newFeature.name" placeholder="Example: Package name" size="large" />
                        </a-card>
                        <a-card class="mt-4 relative" title="Category">
							<div class="absolute" style="top: 15px; left: 88px; font-size: 16px; color: var(--danger);">*</div>
                            <a-select v-model="newFeature.group"  style="width:100%;" size="large" >
                                <a-select-option v-for="group in groups" :value="group.id" :key="group.id" >{{group.name}}</a-select-option>
                            </a-select>
                        </a-card>
                        <a-card class="mt-4" title="Description">
                            <Tiptap v-model="newFeature.description" />
                        </a-card>
                        <a-card class="mt-4 relative" title="Image/Video">
							<div class="absolute" style="top: 15px; left: 112px; font-size: 16px; color: var(--danger);">*</div>
                            <ImageBox :img="newFeature.data.media" @callback="selectImg" @formats="formats" :text="'Add Image/Video'" />
                        </a-card>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {formatDate} from 'bh-mod'
    import BHLoading from 'bh-mod/components/common/Loading'
    import ImageBox from '@/components/features/ImageBox'
    import Tiptap from '@/components/TipTap.vue'

    export default {
        components:{
            BHLoading,ImageBox,Tiptap
        },
        data() {
            return {
                title:'',
                newFeature:{
                    processing:false,
                    status:'draft',
                    name:'',
                    description:'',
                    order:1,
                    thumbnail:'',
                    group:'',
                    data:{
                        mediaType:'image',
                        media:''
                    },
                },
                type:'add',

                duplicate:{
                    name:'',
                    id:'',
                    show:false,
                    load:false
                },
                timeNow:Date.now(),
                mailOpen:'',
                filter:'all',
                baseImage:'',
                loadModal:false,
                taskOpen:'',
                appointmentOpen:'',
                searchMode:false,
                activeTab:'1'
            }
        },
        watch:{
            searchTimeStamp(val){
                this.$store.dispatch('DO_SEARCH')
            },
            currentPage(){
                this.$store.dispatch('DO_SEARCH')
            },
        },
        computed:{
            origFeatures(){
                console.log('ORIGINAL FEATURES', Object.values(this.$store.state.features.features))
                return Object.values(this.$store.state.features.features)
            },
            groups(){
                console.log('GROUPS', Object.values(this.$store.state.features.groups))
                return Object.values(this.$store.state.features.groups)
            },
            currentPage:{
                get(){
                    return this.$store.state.features.currentPage
                },
                set(val){
                    this.$store.commit('SET_CONTACTS_PAGE', val)
                }
            },
            newStoreTime(){
                return this.$store.state.features.newStoreTime
            },
            loadingPage:{
                get(){
                    return this.$store.state.features.loadingPage
                },
                set(val){
                    this.$store.commit('LOAD_PAGE',val)
                }
            }
        },
        methods:{
            deleteFeature(){
                let self = this
                if (this.$p < 40) return this.$message.error('You do not have permission to perform this action')

                this.$confirm({
                    title: 'Delete Feature',
                    content: h => <div>Do you want to delete this feature?</div>,
                    okText: 'DELETE',
                    okType: 'danger',
                    cancelText: 'CANCEL',
                    centered:true,
                    onOk() {
                        console.log('CONFIRM DELETE', self.newFeature)
                        self.$api.delete(`/features/:instance/${self.newFeature.id}`).then( ({data}) => {
                            console.log('DATA FROM DELETE API ', data)
                            self.$store.commit('SET_PROP',{where:['features', data.id], what:data, del:true})
                            self.$router.push('/features')
                            self.$message.success('Feature successfully deleted')
                        }).catch(err => {
                            console.error('ERRR', self.$err(err))
                            self.$message.error(self.$err(err))
                        })
                    },
                    onCancel() {
                        console.log('Cancel');
                    },
                });
            },
            addFeature(){
                if (!this.newFeature.name.trim() || !this.newFeature.thumbnail || !this.newFeature.data.media || !this.newFeature.group) return this.$message.error('Please review and try again.')

                this.$store.commit('LOAD_PAGE',true)

                if (this.type == 'add'){
                    let {group} = this.newFeature
                    let features = this.origFeatures.filter(x => x.group).filter(x => {
                        let groupid = x.group.id ? x.group.id : x.group
                        return groupid === group
                    })
                    let lastNum = 0
                    features.forEach(f => {
                        if (f.order > lastNum) lastNum = f.order
                    })


                    this.newFeature.order = lastNum + 1


                    this.$api.post(`/features/${this.$store.state.instance.id}`, this.newFeature).then( ( {data} ) => {

                        data.group = typeof data.group === 'object'? data.group.id : data.group
                        this.newFeature = JSON.parse(JSON.stringify(data))
                        this.$store.commit('LOAD_PAGE',false)
                        this.$store.commit('SET_PROP',{where:['features', data.id], what:data})

                        console.log('ORIGINAL FEATURES', this.origFeatures )

                        this.$message.success('Successfully added Feature')
                        this.$router.push(`/editfeatures/${data.id}`)
                        setTimeout(() => {
                            this.title = 'Edit Feature & Finishes'
                            this.type = 'edit'
                        }, 500)
                    }).catch(err => {

                        this.$store.commit('LOAD_PAGE',false)
                        console.error('ERRR', this.$err(err))
                        this.$message.error(this.$err(err))

                    })
                } else {
                    console.log('EDITING FEATURE', this.newFeature)

                    this.$api.put(`/features/${this.$store.state.instance.id}/${this.newFeature.id}`, this.newFeature).then( ( {data} ) => {
                        data.group = typeof data.group === 'object'? data.group.id : data.group

                        this.$store.commit('SET_PROP',{where:['features', data.id], what:data})
                        this.$store.commit('LOAD_PAGE',false)
                        this.$message.success('Successfully updated Feature')

                    }).catch(err => {

                        this.$store.commit('LOAD_PAGE',false)
                        console.error('ERRR', this.$err(err))
                        this.$message.error(this.$err(err))

                    })
                }



            },
            selectImg(data) {
                // console.log('data', data)
				console.log('SELECTED IMAGE', data)
                if(!data.includes('https') && data != '') {
                data = 'https://'+data
                }
                this.newFeature.data.media = data
                // this.$store.commit('MEDIA_SELECT',{callback:this.selectedImg,type:'images'})
            },
            selectThumbnail(data) {
                // console.log('data', data)
                if(!data.includes('https') && data != '') {
                data = 'https://'+data
                }
                this.newFeature.thumbnail = data
                // this.$store.commit('MEDIA_SELECT',{callback:this.selectedImg,type:'images'})
            },
            formats(e){
                console.log('FORMATS', e)
            },
            formatDate,
            getDeliveredPerc({counts,contacts}){
                let {delivered = 0} = counts
                return (delivered / contacts.length) * 100
            },
            itemMenuClick( { item, key, keyPath },broadcast) {
                console.log('BROADCAST CLICKED',broadcast,key)
                if (key === 'dup'){
                    this.dupTemplate(broadcast.email)
                } else if (key === 'report'){
                    this.$message.error('This functionality is currently not available')
                }
            },
            async handleDuplicate(){

                this.duplicate.load = true

                let {id,name} = this.duplicate
                let {data} = await this.$api.post(`/emails/${this.instance.id}/clone`,{id,name})

                this.$store.commit('SHOW_EDIT_TEMPLATE',data)
                setTimeout(() => {
                    this.duplicate.load = false
                    this.closeDuplicate()
                }, 1000);

                // console.log('COPIED TEMPLATE CLONEDD******* ',data)
            },
            openTemplate(template){
                console.log('TEMPLATE')
                this.$store.commit('SHOW_EDIT_TEMPLATE',template)
            },
            removeSearch({srch,searchKey}){
                console.log('REMOVING', srch)
                console.log('REMOVING', searchKey)
                this.$store.commit('REMOVE_SEARCH_PARAM',{srch,searchKey})
            },

        },
        mounted(){

        },
        created() {
            console.log('sdafklsadjflkasdjl')
            if (this.$route.params.hasOwnProperty('id')){
                this.title = 'Edit Feature & Finishes'
                let index = this.origFeatures.findIndex(x => x.id == this.$route.params.id)
                this.newFeature = JSON.parse(JSON.stringify(this.origFeatures[index]))
                this.type = 'edit'
            } else {
                this.title = 'Add Feature & Finishes'
                this.type = 'add'
            }
            // $.image_map_pro_init_editor(undefined, $.WCPEditorSettings);

        }

    }
</script>

<style lang="scss" scoped>
    .image-holder-box{
        display: table;
        width: 100%;
        height: 203px;
        vertical-align: top;
        background-color: #fafafa;
        border: 1px dashed #d9d9d9;
        border-radius: 4px;
        cursor: pointer;
    }
    .image-holder-box:hover{
        border-color: var(--orange);
        transition: border-color .3s ease;
    }
    .image-holder-box .content{
        display: table-cell;
        width: 100%;
        height: 100%;
        padding: 8px;
        text-align: center;
        vertical-align: middle;
    }
    .image-holder-box .content .inside{
        background-repeat: no-repeat;
        background-size: contain;
        background-position: 50%;
        width: 100%;
        height: 203px;
    }
    .image-selector {
        position: absolute;
        z-index: 1000;
        width: 100%;
        height:100%;
        justify-content: center;
        align-items: center;
        display: flex;
    }
    @media screen and (min-width: 1250px){
        .responsiveFlex {
            display: flex;
            gap: 1.25em;
        }
        .leftSide {
            width: 75%;
        }
        .rightSide {
            width: 25%;
        }
    }
    @media screen and (max-width: 1250px){
        .responsiveFlex {
            display: block;
        }
        .leftSide {
            width: 100%;
        }
        .rightSide {
            width: 100%;
        }
    }
    .contacts-page{
        @media screen and (min-width:567px){
            margin-top:2%;
        }
    }
    .aB{
        align-items: baseline;
    }

    .row-mb{
        [class^=ant-col]{
            margin-bottom:20px;
        }
    }



</style>
